import React from 'react'
import { Card } from 'web-components'
import Layout from '../containers/Layout'
import home from '../config/text/home.json'
import { imageCDNUrl } from '../helpers'

class Home extends React.Component {
  render () {
    return (
      <Layout heroSrc='heros/coffee-beans-box.jpg' title={home.meta.title}>
        <div className='container home'>
          <div className='row'>
            <div className='col-xs-12'>
              {home.description.map(t => <p>{t}</p>)}
            </div>
          </div>
          <div className='row'>
            {/* Desktop Cards */}
            {home.cards.map(card => {
              return (
                <div className='p-1 col-md'>
                  {/* d-none d-sm-block */}
                  <Card
                    alt={card.img.alt}
                    description={card.description}
                    img={{
                      ...card.img,
                      src: imageCDNUrl(card.img.src)
                    }}
                    name={card.title}
                  />
                </div>
              )
            })}
          </div>
        </div>
      </Layout>
    )
  }
}

export default Home
